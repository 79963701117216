<template>
  <v-app>
    <AppBarComponent />
    <v-main>
      <router-view />
    </v-main>
    <AppFooterComponent />
  </v-app>
</template>

<script>
import '@/animations/animations.scss'
import '@/styles/custom.scss'
// import AppBarComponent from '@/components/AppBarComponent/AppBarComponent'
// import AppFooterComponent from '@/components/AppFooterComponent/AppFooterComponent'
import scroll from '@/mixins/scroll'

export default {
  name: 'App',
  components: { /* AppFooterComponent, AppBarComponent */ },
  data: () => ({
    //
  }),
  mixins: [scroll]
}
</script>

<style type="text/scss">
  @import url('https://fonts.cdnfonts.com/css/sf-pro-display');
  @import url('https://fonts.cdnfonts.com/css/verdana');
  @import url('https://fonts.cdnfonts.com/css/helvetica-neue-9');

  * {
    font-family: 'Roboto', sans-serif;
  }
</style>
