export default {
  data () {
    return {
    }
  },
  mounted () {
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  updated () {
    console.log('update')
    window.scrollTo(0, 0)
    console.log(window.scrollY)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll (event) {
      this.$store.state.scrollY = window.scrollY
    }
  }
}
