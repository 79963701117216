import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
/*
import HomeView from '@/views/HomeView/HomeView'
import AboutView from '@/views/AboutView/AboutView'
import iD4GoodView from '@/views/iD4GoodView/iD4GoodView'
import Box4GoodView from '@/views/Box4GoodView/Box4GoodView'
import Pay4GoodView from '@/views/Pay4GoodView/Pay4GoodView'
import DeptOfGoodView from '@/views/DeptOfGoodView/DeptOfGoodView'
import Wallet4GoodView from '@/views/Wallet4GoodView/Wallet4GoodView'
import Node4GoodView from '@/views/Node4GoodView/Node4GoodView'
import GetStartedView from '@/views/GetStartedView/GetStartedView'
import EmpowermentView from '@/views/EmpowermentView/EmpowermentView'
import AboutUsView from '@/views/AboutUsView/AboutUsView'
import OurPartnersView from '@/views/OurPartnersView/OurPartnersView'
import Community4GoodView from '@/views/Community4GoodView/Community4GoodView'
import Network4GoodView from '@/views/Network4GoodView/Network4GoodView'
import Sandbox4GoodView from '@/views/Sandbox4GoodView/Sandbox4GoodView'
import ContactView from '@/views/ContactView/ContactView'
import TestingView from '@/views/TestingView/TestingView'
import Pay4GlobalView from '@/views/Pay4GlobalView/Pay4GlobalView.vue'
*/
import MaintenanceView from '@/views/MaintenanceView/MaintenanceView.vue'

Vue.use(VueRouter)
Vue.use(Meta)

const routes = [
  {
    path: '*',
    name: 'Maintenance',
    component: MaintenanceView
  }]
/*
{
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/id4good',
    name: 'id4good',
    component: iD4GoodView
  },
  {
    path: '/box4good',
    name: 'box4good',
    component: Box4GoodView
  },
  {
    path: '/deptofgood',
    name: 'deptofgood',
    component: DeptOfGoodView
  },
  {
    path: '/wallet4good',
    name: 'wallet4good',
    component: Wallet4GoodView
  },
  {
    path: '/getstarted',
    name: 'getstarted',
    component: GetStartedView
  },
  {
    path: '/empowerment',
    name: 'empowerment',
    component: EmpowermentView
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    component: AboutUsView
  },
  {
    path: '/partners',
    name: 'partners',
    component: OurPartnersView
  },
  {
    path: '/community',
    name: 'community',
    component: Community4GoodView
  },
  {
    path: '/node4good',
    name: 'node4good',
    component: Node4GoodView
  },
  {
    path: '/network4good',
    name: 'network4good',
    component: Network4GoodView
  },
  {
    path: '/sandbox',
    name: 'sandbox',
    component: Sandbox4GoodView
  },
  {
    path: '/pay4good',
    name: 'pay4good',
    component: Pay4GoodView
  },
  {
    path: '/pay4global',
    name: 'pay4global',
    component: Pay4GlobalView
  },
  {
    path: '/testing',
    name: 'testing',
    component: TestingView
  }
] */

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
