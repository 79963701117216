<template>
    <div class="MaintenanceView">
      <v-container class="fill-height">
        <v-row>
          <v-col align-self="center" align="center">
            <v-img :src="require('@/assets/logo.png')" max-width="1500px" width="auto" max-height="200px" contain/>
            <div class="message">
              Coming soon
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_MaintenanceView.scss'
export default {
  name: 'MaintenanceView',
  mounted () {
  },
  beforeDestroy () {
    this.$store.commit('closeLoading')
  },
  watch: {
  },
  computed: {
  },
  methods: {
  }
}
</script>
